<section class="row">
    <div class="slide1-bg">
        <div id="main">
            <div id="box1"></div>
            <div id="box2">
                <div id="text">
                    <!-- A Touchfree Menu <br /> -->
                    <span id="sub-text">
                        A Safe, Simple, Free Mobile Catalog System
                    </span>
                </div>
            </div>

        </div>
    </div>

</section>
<section class="row" style="background: white;">
    <div class="col-12 section2-head">Scan the code to see the catalog</div>
    <div style="margin: 0 auto;">
        <img src="../../assets/Phone menu .png" class="aos-item mobile" />
    </div>
    <div style="width: 60%;;margin: auto 50px;" class="aos-item pt-5 text-nowrap" data-aos="slide-left" data-aos-duration="10000">
        <div class="section2-content">
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color ">check</i> <span>
                    No app to download.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    No hardware to install.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    Great for shopping catalog, restaurant menus.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    No physical contact in shops, hotels, restaurants.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    Optimized for all devices.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    Contactless is here for the long haul.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    Re-ordering.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    Less wait-staff assistance.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    Unlimited catalog and unlimited scans.
                </span>
            </div>
        </div>
    </div>
</section>
<section class="row">
    <div class="col-12 section2-head" style="color: white !important;">User comments</div>

    <div class="testimonial-section">
        <div class="testi-user-img">
            <div class="swiper-container gallery-thumbs">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <img class="u1" src="../../assets/testimonial1.jpg" alt="">
                    </div>
                    <div class="swiper-slide">
                        <img class="u1" src="../../assets/whitespace.jpg" alt="">
                    </div>
                    <div class="swiper-slide">
                        <img class="u2" src="../../assets/whitespace.jpg" alt="">
                    </div>

                    <div class="swiper-slide">
                        <img class="u4" src="../../assets/whitespace.jpg" alt="">
                    </div>
                    <div class="swiper-slide">
                        <img class="u5" src="../../assets/testimonial5.jpg" alt="">
                    </div>
                    <div class="swiper-slide">
                        <img class="u6" src="../../assets/whitespace.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="user-saying">
            <div class="swiper-container testimonial">
                <!-- Additional required wrapper -->
                <div class="swiper-wrapper ">
                    <!-- Slides -->
                    <div class="swiper-slide">
                        <div class="quote">
                            <img class="quote-icon" src="../../assets/quote.png" alt="">
                            <p>
                                “I am glad my dine in customers can easily access our menu through mobile phone with QR code from touchfreewaiter.com..“
                            </p>
                            <div class="name">-Junaid Ahmed-</div>
                            <div class="designation">Butterfly Effect Cafe - Chennai</div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="quote">
                            <img class="quote-icon" src="../../assets/quote.png" alt="">

                            <p>
                                “I can now change my items in the menu and modify my food prices on a daily basis..“
                            </p>
                            <!-- <div class="name">-XXX-</div> -->
                            <div class="designation">Restaurant Owner</div>

                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="quote">
                            <img class="quote-icon" src="../../assets/quote.png" alt="">

                            <p>
                                “I always look for ways to avoid touching the physical menu in restaurants, especially in these COVID times...“
                            </p>
                            <div class="name">-Baranidharan Nithiyananthan-</div>
                            <div class="designation">Thiruvanmiyur - Chennai</div>

                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="quote">
                            <img class="quote-icon" src="../../assets/quote.png" alt="">

                            <p>
                                “Scanning QR code and accessing the menu through my mobile phone helps me reduce contact points in the restaurants and keep my kids safe...“
                            </p>
                            <div class="name">-Hima Penumuru-</div>
                            <div class="designation">Malleshwaram - Bengaluru</div>

                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="quote">
                            <img class="quote-icon" src="../../assets/quote.png" alt="">

                            <p>
                                “No more guesswork if the menu card is sanitized before I touch it...“
                            </p>
                            <div class="name">-Devi Nadimpally -</div>
                            <div class="designation">Kothapet, Hyderabad</div>

                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="quote">
                            <img class="quote-icon" src="../../assets/quote.png" alt="">

                            <p>
                                “I hate to raise my hand and ask the waiter for a menu card. With QR code in front of me, I can lookup the menu whenever I want..“
                            </p>
                            <div class="name">-Devaraj Narayan-</div>
                            <div class="designation">Ottapalam, Palakkad</div>

                        </div>
                    </div>

                </div>
                <div class="swiper-pagination swiper-pagination-white" style="right: 40px !important;"></div>

            </div>
        </div>
    </div>
</section>
<!-- <div id="imagePrincipale">
    <h1 class="aos-item" data-aos="zoom-in" data-aos-delay="500" data-aos-duration="2000" data-aos-once="false">
        Touchfree Waiter</h1>
    <div id="premierTrait" class="aos-item" data-aos="flip-left" data-aos-duration="3000"></div>
    <h3 class="aos-item" data-aos="zoom-out-up" data-aos-delay="1500" data-aos-duration="3000" data-aos-once="false">
        bon appétit
    </h3>
</div> -->
<section class="row" style="background: white;">
    <div class="col text-center">
        <span>Copyrights© 2021 All Rights Reserved</span>
    </div>
</section>