<div *ngIf="!usermode" class="page-no-overflow " [ngClass]="{'pageoverflow' : homepage}">
    <mat-toolbar color="">
        <mat-toolbar-row>
            <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
        <i class="material-icons homeicon">home</i>
      </button>
            <span><div class="header-name" style="font-family:Merienda">Touchfree Waiter</div></span>
            <span class="menu-spacer"></span>
            <div class="row pr-3" fxShow="true" fxHide.lt-md>
                <div *ngFor="let nav of navlist;let i=index;">
                    <a href="{{nav.href}}" *ngIf="(!loginstatus && i != 5) || (loginstatus && i != 4)" [class]="{ 'active-nav' : selectedTab == i}" (click)="navHeader(i)" mat-button>{{nav.title}}</a>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container fxFlexFill>
        <mat-sidenav #sidenav>
            <mat-nav-list (click)="sidenav.toggle()">
                <a href="/#/home" mat-list-item><span class="material-icons" style="padding: 10px;color: #0056b3;">home</span>Home</a>
                <a href="/#/menu-dashboard" mat-list-item><span class="material-icons" style="padding: 10px;color: #0056b3;">menu_book</span> Catalog</a>
                <a href="/#/qrcode" mat-list-item><span class="material-icons" style="padding: 10px;color: #0056b3;">qr_code_2</span> QR Code</a>
                <a href="/#/about" mat-list-item> <span class="material-icons" style="padding: 10px;color: #0056b3;">notes</span> About</a>
                <a *ngIf="!loginstatus" href="/#/login" mat-list-item><i class="fa fa-sign-in" aria-hidden="true" style="padding: 10px;color: #0056b3;font-size: 24px;"></i>Login</a>
                <a *ngIf="loginstatus" href="/#/home" mat-list-item (click)="logout()"><i class="fa fa-sign-out" aria-hidden="true" style="padding: 10px;color: #0056b3;font-size: 24px;"></i>Logout</a>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content fxFlexFill [ngClass]="{'homebg' : getbackground()}">
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
<div *ngIf="usermode" class="p-3">
    <router-outlet></router-outlet>
</div>
<ngx-loading-x [show]="load"></ngx-loading-x>