import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  name = "Angular"
  ngOnInit(): void {
  }

  public LoadUrl(url:string) {
    window.open(url)
   }

   public SendMail(mailId:string) {
    window.open("mailto:support@touchfreewaiter.com?subject=Support&body=Hi")
   }
  }