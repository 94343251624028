import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheet, MatBottomSheetConfig, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition } from '@ngx-gallery/core';
import { CookieService } from 'ngx-cookie-service';
import { AddmenuComponent } from '../addmenu/addmenu.component';
import { AppServiceService } from '../app-service.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.css']
})
export class MenusComponent implements OnInit {

  data : any = [];
  username: string;
  deleteactive : boolean = false;
  selectall : boolean = false;
  selectedmenu : any = [];
  IdentityPoolId : string;
  items: GalleryItem[]; 
  constructor(public gallery: Gallery,public dialog: MatDialog, public cookies: CookieService, public router: Router, 
    private service : AppServiceService, public appmod : AppComponent,  private _bottomSheet: MatBottomSheet) {
      if (appmod.loginstatus)
        appmod.selectedTab = 1;
      appmod.load = true;
     }

  ngOnInit(): void {
    this.username = this.cookies.get('LoginUser');
    if (this.username){
      this.getCustomerMenu()
    }
    else{
      this.appmod.load = false;
      this.router.navigate(['/', 'login']);
    }
    
  }
  getCustomerMenu(){
    this.items = []
    var body = {
      'username' : this.username
    }
    
    this.service.postCall(body,'/menulist').subscribe((response) =>{
    if(response['statusCode'] == 200)
      {
        if(response['images']){
          this.appmod.load = false;
          for(var i=0;i<response['images'].length; i++){
            this.data[response['images'][i]['order']] = response['images'][i]
          }
          console.log(this.data)
          this.selectedmenu = this.data;
          this.items = this.data.map(item =>
            new ImageItem({ src: item['image'], thumb: item['image']})
        );
        this.selectedmenu.forEach(item =>{
          delete item['image'];
          item['check'] = false;
        })
        }
        this.withCustomGalleryConfig();
        this.router.navigate(['/', 'menu-dashboard']);
      }
      if(response['statusCode'] == 202){
        window.alert(response['message'])
        this.appmod.load = false;
        this.addMenu(true)
      }
    }, (error) => {
      console.log('Error', error)
    })
  }
  withCustomGalleryConfig() {

    const lightboxGalleryRef = this.gallery.ref('lightbox');
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Bottom,
      dots : true,
      counter : false,
      loop : false,
      loadingStrategy : 'preload',
      thumbMode : 'free'
    });
    lightboxGalleryRef.load(this.items);
  }
  addMenu(drop){
    const dialogRef = this.dialog.open(AddmenuComponent, {
      minWidth: '50vw',
      position:{
        top:'15vh'
      },
      hasBackdrop: !drop,
      backdropClass: 'backdropBackground',
      data: { username: this.username, imgloaded : false}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  openDeletemenu(){
    this.deleteactive = !this.deleteactive;
    const config: MatBottomSheetConfig = {
      panelClass: 'addmenu-optionslist',
      data: {
        "menu" : this.selectedmenu,
        "username" : this.username,
        "deleteactive" : this.deleteactive
      },
      hasBackdrop: false

    }
    const bottomSheetRef = this._bottomSheet.open(DeletemenuComponent, config)
    bottomSheetRef.afterDismissed().subscribe((dataFromChild) => {
      this.deleteactive = dataFromChild;
      this.selectedmenu.forEach(item =>{
        item.check = false;
      })
      this.selectall = false;
    });
  }
  selectallmenu(){
    this.selectall = !this.selectall;
    if(!this.selectall)[
      this.selectedmenu.forEach(item =>{
        item.check = false;
      })
    ]
    else{
      this.selectedmenu.forEach(item =>{
        item.check = true;
      })
    }
  }
  selectmenu(index){
    if (this.selectedmenu[index]['check'])
      this.selectedmenu[index]['check'] = false
    else
    this.selectedmenu[index]['check'] = true
    var filterList = this.selectedmenu.filter( p => !p.check)
    if(filterList.length !=0)
      this.selectall = false
    else
      this.selectall = true
    console.log(this.selectedmenu)
  }
}

@Component({
  selector: 'app-menus',
  templateUrl: './deletemenu.component.html',
  styleUrls: ['./menus.component.css']
})
export class DeletemenuComponent implements OnInit {

  menulist: any;
  constructor(private _bottomSheetRef: MatBottomSheetRef<DeletemenuComponent>, private _bottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, public service: AppServiceService) {
      this.menulist = data['menu']
     }
  ngOnInit(): void {
    
    
}
deletemenu(){
  // this.appmod.load = true;
  console.log(this.data['menu'])
  var fileList = [];
  this.menulist.forEach(file => {
    if (file.check)
      fileList.push(file.filename)
  });
  if(fileList.length != 0){
    var body = {
      "delete_list" : fileList,
      "username" : this.data['username']
    }
    console.log(body)
    this.service.postCall(body , '/deletemenu').subscribe((response) => {
      if(response){
        location.reload();
      }
    })
  }
  else{
    this._bottomSheetRef.dismiss(false);
  }
}
canceldelete(){
  this._bottomSheetRef.dismiss(false);
}
}
