<div class="card">
    <div class="card-header justify-content-between d-flex menu-header">
        <div class="">Catalog</div>
        <div class="d-flex" style="cursor: pointer;">
                <span *ngIf="!deleteactive" class="material-icons qricon" [routerLink]="['/qrcode']"> qr_code_2 </span>
            <div (click)="addMenu(false)" class="px-4">
                <span *ngIf="!deleteactive" class="material-icons"> add_box </span>
            </div>
            <div>
                <span *ngIf="!deleteactive" class="material-icons" (click)="openDeletemenu()"> delete </span>
            </div>
        </div>
    </div>
    <div *ngIf="deleteactive" class="d-flex px-4 py-2 text-primary">
        <span *ngIf="!selectall" class="material-icons" (click)="selectallmenu()"> select_all </span>
        <span *ngIf="selectall" class="material-icons" (click)="selectallmenu()"> check_box </span>
        <span>Select all</span>
    </div>
    <div class="card-body row m-2" style="height: 80vh; overflow: auto">
        <div class="" *ngFor="let item of items; index as i;" style="position: relative;">
            <section *ngIf="deleteactive" class="example-section checkbox-pos">
                <mat-checkbox class="example-margin" [color]="primary" [checked]="selectedmenu[i].check" (change)="selectmenu(i)"></mat-checkbox>
            </section>
            <div>
                <img [ngClass]="{'bg-secondary' : selectedmenu[i].check }"class="col mat-stroked-button " [lightbox]="i " [gallery]=" 'lightbox' " [src]="item.data.thumb " />
            </div>
        </div>
    </div>
</div>