<div class="p-1">
    <div class="d-flex ">
        <button mat-raised-button class="col blue-text" style="border-radius: 15px;" (click)="deletemenu()">
            <i class="material-icons font-26 ">restore_from_trash</i>
            <span class="p-2 font-15">Delete</span>
        </button>
    </div>
    <div class="mt-2 d-flex">
        <button mat-raised-button class="col text-danger" style="border-radius: 15px;" (click)="canceldelete()">
            <i class="material-icons font-26 ">delete_forever</i>
            <span class="p-2 font-15">Cancel</span>
        </button>
    </div>
</div>