<div style="height: 100vh; display:flex; justify-content: center;">
    <div>
        <!--div style="font-weight: bold;">
            Contact Us
        </div-->
        <div id="icons" style="display: inline;"> 
            <a id="icon" ></a><img src="assets/whatsapp.png" width="25" height="25" onmouseover="" style="cursor: pointer;" (click)="LoadUrl('https://wa.me/+918778221132')"/>
            <a id="icon" ></a><img src="assets/facebook.png" width="25" height="25" onmouseover="" style="cursor: pointer;" (click)="LoadUrl('https://www.facebook.com/touchfreewaiter')"/>     
            <a id="icon" ></a><img src="assets/Instagram.png" width="25" height="25" onmouseover="" style="cursor: pointer;" (click)="LoadUrl('https://www.instagram.com/touchfreewaiter')"/>   
            <a id="icon" ></a><img src="assets/email.png" width="25" height="25" onmouseover="" style="cursor: pointer;" (click)="SendMail('support@touchfreewaiter.com')"/>
        </div>

        <div style="font-weight: bold; text-align: center; padding: 20px;">
            <a href="https://goo.gl/maps/kK3eA5PsTTDsrW6G6" target="_blank" rel="noopener nofollow"><em class="fa fa-map-marker"></em> Office Location</a>
        </div>

        <div class="address" style="text-align: center;">
            <p>No.3 6th Cross Street </p> 
            <p>Balaji Nagar, Ekkattuthangal </p> 
            <p>Chennai 600032</p>
        </div>
    </div>
</div>

